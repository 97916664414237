import type { ButtonBaseProps } from "./utils";
import { Link } from "@remix-run/react";
import { classNames } from "~/utils/formatters";
import { forwardRef } from "react";
import { appearances, buttonSizes } from "./utils";

type RemixLinkProps = Parameters<typeof Link>[0];

type AnchorWithToProps = {
  href?: never;
  to: RemixLinkProps["to"];
} & Omit<RemixLinkProps, "className" | "ref">;

type AnchorWithHrefProps = {
  href: string;
  to?: never;
} & Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  "className" | "ref" | "href"
>;

const Anchor = forwardRef(
  (
    {
      children,
      to,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      appearance = "primary",
      block = false,
      rounded = false,
      size = "md",
      ...linkProps
    }: (AnchorWithToProps | AnchorWithHrefProps) & ButtonBaseProps,
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    const classes = classNames(
      block ? "flex w-full" : "inline-flex",
      rounded ? "rounded-full" : "rounded-3xl",
      buttonSizes[size][0],
      appearances[appearance][0],
      appearances[appearance][1]["idle"],
      "relative items-center justify-center font-bold transition focus:outline-none focus:ring-2 focus:ring-sky-400",
    );

    if (to) {
      return (
        <Link ref={ref} to={to} className={classes} {...linkProps}>
          {LeftIcon ? (
            <LeftIcon className={buttonSizes[size][1]["leftIcon"]} />
          ) : null}
          {children}
          {RightIcon ? (
            <RightIcon className={buttonSizes[size][1]["rightIcon"]} />
          ) : null}
        </Link>
      );
    }

    return (
      <a
        ref={ref}
        className={classes}
        target="_blank"
        rel="noreferrer"
        {...linkProps}
      >
        {LeftIcon ? (
          <LeftIcon className={buttonSizes[size][1]["leftIcon"]} />
        ) : null}
        {children}
        {RightIcon ? (
          <RightIcon className={buttonSizes[size][1]["rightIcon"]} />
        ) : null}
      </a>
    );
  },
);
if (process.env.NODE_ENV === "development") {
  Anchor.displayName = "Anchor";
}

export { Anchor };
export type { AnchorWithToProps, AnchorWithHrefProps };
